import axios from "axios";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { HTTP_CLIENT } from "../utils/axiosClient";

const handleUserLogin = async (params) => {
  // return await HTTP_CLIENT.post("/auth/login", params);
};

export { handleUserLogin };

export const getCurrentRate = async (tokenAddress) => {
  try {
    const modifyURL = `https://api.coingecko.com/api/v3/simple/token_price/zksync?contract_addresses=${tokenAddress}&vs_currencies=usd`;
    let price;
    const response = await axios.get(modifyURL);
    for (const [key, value] of Object.entries(response.data)) {
      price = value;
    }
    return price?.usd;
  } catch (error) {
    toast.error(error?.response?.data?.message, {
      className:
        "text-[14px] xl:text-[20px]  leading-[22px] xl:leading-[28px] font-medium text-[#555555]",
    });
  }
};

export const tokenRateINUsd = async () => {
  try {
    const responseURL = "https://apiv5.paraswap.io/prices/?srcToken=0xae78736Cd615f374D3085123A210448E74Fc6393&destToken=0xdAC17F958D2ee523a2206206994597C13D831ec7&amount=1000000000000000000&srcDecimals=18&destDecimals=18&side=SELL&network=1";

    const response = await axios.get(responseURL);
    let price = ethers.utils.formatUnits(response?.data?.priceRoute?.destAmount, response?.data?.priceRoute?.destDecimals)

    return price
  } catch (error) {
    toast.error(error?.response?.data?.message, {
      className:
        "text-[14px] xl:text-[20px]  leading-[22px] xl:leading-[28px] font-medium text-[#555555]",
    });
  }
}


export const findETHPrice = async () => {
  try {
    const responseURL = "https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT";
    const response = await axios.get(responseURL);
    return response?.data?.price
  } catch (error) {

  }
}

export const getMaintenanceMode = async () => {
  return HTTP_CLIENT.get("api/user/get-maintenance")
}