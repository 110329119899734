const testnetConfig = {
  Base_URL: "http://localhost:8080/",
};

const mainnetConfig = {
  Base_URL: "https://api.nbxexchange.com",
};

const stagingConfig = {
  Base_URL: "https://api.nbxexchange.com",
};

const defaultConfig = mainnetConfig;

export default defaultConfig;
