import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  slipageValue: null,
  gasPriceValue: null,
};

export const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {
    setSlipage: (state, action) => {
      state.slipageValue = action.payload;
    },
    setgasPrice: (state, action) => {
      state.gasPriceValue = action.payload;
    },
  },
});

export const { setSlipage, setgasPrice } = swapSlice.actions;

export default swapSlice.reducer;
