import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  accessToken: null,
  maintenanceCheck: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState: (state) => initialState,

    saveAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    saveMaintenanceCheck: (state, action) => {
      state.maintenanceCheck = action.payload
    }
  },
});

export const { saveAccessToken, resetUserState, saveMaintenanceCheck } = userSlice.actions;

export default userSlice.reducer;
