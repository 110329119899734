import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: null,
};

export const zkAutoSlice = createSlice({
  name: "zkAuto",
  initialState,
  reducers: {
    saveUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const { saveUserData } = zkAutoSlice.actions;

export default zkAutoSlice.reducer;
