import WalletConnectProvider from "@walletconnect/web3-provider";

// ----- Cridentials for Live starts here-----//

export const ChainID = 324;
export const RPC_URL = "https://mainnet.era.zksync.io"
export const blockExplorerUrls = "https://explorer.zksync.io/"
export const currencySymbol = "ETH"
export const WebSocket_URL = "wss://mainnet.era.zksync.io/ws"

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        324: `${RPC_URL}`,
      },
      chainId: `${ChainID}`,
      network: "zkSync Era Mainnet",
    },
  },
};

// ----- Cridentials for Live ends here-----//

// // ----- Cridentials for Stagging starts here-----//
// export const ChainID = 280;
// export const RPC_URL = "https://testnet.era.zksync.dev"
// export const blockExplorerUrls = "https://goerli.explorer.zksync.io/"
// export const currencySymbol = "ETH"
// export const WebSocket_URL = "wss://mainnet.era.zksync.io/ws"

// export const providerOptions = {
//   walletconnect: {
//     package: WalletConnectProvider,
//     options: {
//       rpc: {
//         280: `${RPC_URL}`,
//       },
//       chainId: `${ChainID}`,
//       network: "zkSync Era Testnet",
//     },
//   },
// };
// // ----- Cridentials for Stagging ends here-----///

// ----- Cridentials for Stagging ends here-----///

// export const ChainID = 80001;
// export const RPC_URL = "https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78"
// export const blockExplorerUrls = "https://rpc-mumbai.maticvigil.com/"
// export const currencySymbol = "MATIC"


// export const providerOptions = {
//   walletconnect: {
//     package: WalletConnectProvider,
//     options: {
//       rpc: {
//         80001: `${RPC_URL}`,
//       },
//       chainId: `${ChainID}`,
//       network: "Mumbai testnet",
//     },
//   },
// };