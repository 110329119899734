/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getAccountDetails,
  toHex,
  walletConnectModal,
} from "./web3ModalConnector";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { ChainID, RPC_URL, blockExplorerUrls } from "../constant/constant";
import {
  setChainId,
  setMetaMaskToken,
} from "../../store/reducers/metamasktoken";

const UseModalInjector = () => {
  const dispatch = useDispatch();
  const { metaMaskToken, chainId, webProvider, library } = useSelector(
    (state) => state.token
  );

  const { connector, deactivate, disconnect } = useWeb3React();

  const [error, setError] = useState();
  const isWrongNetwork = error && error instanceof UnsupportedChainIdError;
  const [connectModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [check, setCheck] = useState(false);

  const mobileMenuHandler = async () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const networks = {
    324: {
      chainId: `0x${Number(324).toString(16)}`,
      chainName: "zkSync Era Mainnet",
      nativeCurrency: {
        name: "zkSync",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [RPC_URL],
      blockExplorerUrls: [blockExplorerUrls],
    },
    280: {
      chainId: `0x${Number(280).toString(16)}`,
      chainName: "zkSync Era Testnet",
      nativeCurrency: {
        name: "zkSync",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [RPC_URL],
      blockExplorerUrls: [blockExplorerUrls],
    },
    80001: {
      chainId: `0x${Number(80001).toString(16)}`,
      chainName: "Polygon Mumbai Testnet",
      nativeCurrency: {
        name: "Mumbai",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: [
        "https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78",
      ],
      blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    },
  };
  useEffect(() => {
    if (window?.ethereum?.on) {
      const handleAccountsChanged = (accounts) => {
        dispatch(setMetaMaskToken(accounts[0]));
      };

      const handleChainChanged = async (chainId) => {
        const convertedChainId = parseInt(chainId, 16);
        dispatch(setChainId(convertedChainId));
        if (chainId !== ChainID) {
          switchNetwork();
        }
      };

      const handleDisconnect = async () => {
        await disconnect();
      };

      window?.ethereum?.on("accountsChanged", handleAccountsChanged);
      window?.ethereum?.on("chainChanged", handleChainChanged);
      window?.ethereum?.on("disconnect", handleDisconnect);

      return () => {
        if (window?.ethereum?.removeListener) {
          window?.ethereum?.removeListener(
            "accountsChanged",
            handleAccountsChanged
          );
          window?.ethereum?.removeListener("chainChanged", handleChainChanged);
          window?.ethereum?.removeListener("disconnect", handleDisconnect);
        }
      };
    } else {
      if (webProvider?.on) {
        const handleAccountsChanged = (accounts) => {
          dispatch(setMetaMaskToken(accounts[0]));
        };

        const handleChainChanged = async (chainId) => {
          const convertedChainId = parseInt(chainId, 16);
          dispatch(setChainId(convertedChainId));
          if (chainId !== ChainID) {
            switchNetwork();
          }
        };

        const handleDisconnect = async () => {
          await disconnect();
        };

        webProvider.on("accountsChanged", handleAccountsChanged);
        webProvider.on("chainChanged", handleChainChanged);
        webProvider.on("disconnect", handleDisconnect);

        return () => {
          if (webProvider.removeListener) {
            webProvider.removeListener(
              "accountsChanged",
              handleAccountsChanged
            );
            webProvider.removeListener("chainChanged", handleChainChanged);
            webProvider.removeListener("disconnect", handleDisconnect);
          }
        };
      }
    }
  }, [disconnect, dispatch, webProvider]);

  const handleDisconnect = async () => {
    if (connector && connector.walletConnectProvider) {
      connector.walletConnectProvider = undefined;
    }
    try {
      deactivate();
      dispatch(setMetaMaskToken(""));
      setCheck(false);
    } catch (ex) { }
  };
  const switchNetwork = async (chain = ChainID) => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(chain) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          const networkObject = networks?.[Number(chain)];
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                ...networkObject,
              },
            ],
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const switchToNetwork = useCallback(
    async (chain = ChainID) => {
      if (window?.ethereum !== undefined) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: toHex(chain) }],
          });
        } catch (err) {
          const networkObject = networks?.[Number(chain)];
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  ...networkObject,
                },
              ],
            });
          }
        }
      } else {
        try {
          await library.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: toHex(chain) }],
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              const networkObject = networks?.[Number(chain)];

              await library.provider.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    ...networkObject,
                  },
                ],
              });
            } catch (error) {
              setError(error);
            }
          }
        }
      }
    },
    [library]
  );

  const handleConnectModal = async () => {
    try {
      if (window.ethereum === undefined) {
        if (
          /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          window?.open(
            "https://metamask.app.link/dapp/https://beta.nbx.exchange/"
          );
        } else {
          window
            ?.open(
              "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
              "_blank"
            )
            ?.focus();
        }
      } else if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // open the deeplink page
        window?.open(
          "https://metamask.app.link/dapp/https://beta.nbx.exchange/"
        );
      } else {
        await walletConnectModal();
        await getAccountDetails();
      }
    } catch (error) { }
  };
  const handleMobileConnectModal = async () => {
    try {
      if (window?.ethereum !== undefined && window?.ethereum?.isMetaMask) {
        await walletConnectModal();
        await getAccountDetails();
      } else {
        await handleConnectModal();
        await walletConnectModal();
        await getAccountDetails();
      }
    } catch (error) {
      toast.error("wallet connect errors", {
        className:
          "text-[14px] xl:text-[20px]  leading-[22px] xl:leading-[28px] font-medium text-[#555555]",
      });
    }
  };

  useEffect(() => {
    metaMaskToken && switchNetwork();
  }, [metaMaskToken, chainId, switchNetwork]);

  return {
    handleConnectModal,
    isMobileMenuOpen,
    mobileMenuHandler,
    metaMaskToken,
    chainId,
    connectModalOpen,
    isWrongNetwork,
    handleMobileConnectModal,
    check,
    handleDisconnect,
    walletConnectModal,
    getAccountDetails,
    switchToNetwork,
  };
};

export default UseModalInjector;
