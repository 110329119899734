import { combineReducers, compose } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import ReduxThunk from "redux-thunk";
import userSlice from "./reducers/user";
import themeReducer from "./reducers/themeSlice";
import metaMaskTokenSlice from "./reducers/metamasktoken";
import swapSlice from "./reducers/swapReducer";
import dashboardSlice from "./reducers/dashboardReducer";
import zkAutoSlice from "./reducers/ZkAutoReducer";
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user", "theme", "token"],
  blacklist: [],
  transforms: [],
};

const reducers = combineReducers({
  user: userSlice,
  theme: themeReducer,
  token: metaMaskTokenSlice,
  swap: swapSlice,
  dashboard: dashboardSlice,
  zkAuto: zkAutoSlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false,
}).concat(ReduxThunk);

let enhancedCompose = compose;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: enhancedCompose(middleware),
});

export const persistor = persistStore(store);
