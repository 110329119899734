import { Bars, ThreeDots } from "react-loader-spinner";
import StylesCompo from "./CompoLoader.module.css";
import styles from "./loader.module.scss";
import Typewriter from 'typewriter-effect';
// import { gsap } from "gsap";
// import SplitTextJS from "split-text-js";
// import SplitType from "split-type";
// import { useEffect, useState } from "react";
// import { PixiPlugin } from "gsap/PixiPlugin.js";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
//without this line, PixiPlugin and MotionPathPlugin may get dropped by your bundler (tree shaking)...
// gsap.registerPlugin(PixiPlugin, MotionPathPlugin);





export const BarLoader = ({ height, width }) => {
  return (
    <>
      <Bars
        height={height}
        width={width}
        color="#fff"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </>
  );
};



export const CompLoader = () => {
  // co
  // const titles = gsap.utils.toArray('p');
  // const tl = gsap.timeline();

  // titles.array.forEach(element => {
  //   let first = 'TIRED OF DOING EVERYTHING?'
  //   let second = 'JUST CHILL AND LEAVE IT TO US'

  // });
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {/* <p>TIRED OF DOING EVERYTHING?</p>
        <p>JUST CHILL AND LEAVE IT TO US</p> */}
        <ThreeDots
          height="70"
          width="70"
          radius="9"
          color="#FF8049"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  )
}

export const PopupCompLoader = () => {
  return (
    <div className={StylesCompo.PopupContainer}>
      <div className={StylesCompo.PopupWrapper}>
        <div className={StylesCompo.PopupLogo}>
          {/* <img src={"/navbarlogo.svg"} alt="" style={{ cursor: "pointer" }} /> */}
        </div>
        <ThreeDots
          height="70"
          width="70"
          radius="9"
          color="#FF8049"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  )
}

export const ZkAutoFarmLoader = () => {
  return (
    <div className="w-full h-full flex flex-col gap-y-[20px] justify-center items-center relative">
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
      <div className={styles.zkAutoFarmWrapperFirst}>
        <Typewriter
          options={{
            strings: ['Scanning for the highest yield on zk Sync.'],
            autoStart: true,
            loop: true,
            deleteSpeed: 1,
          }}
        />
      </div>

    </div>
  )
}

export const DashboardZkAutoFarmLoader = () => {
  return (
    <div className="w-full h-full min-h-[60vh] flex flex-col gap-y-[20px] justify-center items-center relative">
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
      <div className={styles.zkAutoFarmWrapperFirst}>
        <Typewriter
          options={{
            strings: ['Collecting all your assets!'],
            autoStart: true,
            loop: true,
            deleteSpeed: 1,
          }}
        />
      </div>

    </div>
  )
}