import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  metaMaskToken: null,
  webProvider: null,
  chainId: null,
  library: null,
  network: null,
  useMobileWallet: false,
  connectStatus: null,
  Window: undefined,
  disconnectWallet: false,
  cuurentProvder: "",
  libraryProvider: "",
  sliderBoolean: false
};
export const metaMaskTokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setMetaMaskToken(state, action) {
      state.metaMaskToken = action.payload;
    },
    setProvider(state, action) {
      state.webProvider = action.payload;
    },
    setChainId: (state, action) => {
      state.chainId = action.payload;
    },
    setMobileUIWallet: (state, action) => {
      state.useMobileWallet = action.payload;
    },
    setLibrary: (state, action) => {
      state.library = action.payload;
    },
    setSliderBoolean: (state, action) => {
      state.sliderBoolean = action.payload;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setNetworkStatus: (state, action) => {
      state.connectStatus = action.payload
    },
    saveWindow: (state, action) => {
      state.Window = action.payload
    },
    setDisconnectWallet: (state, action) => {
      state.disconnectWallet = action.payload;
    },
    setCurrentProvider: (state, action) => {
      state.cuurentProvder = action.payload;
    },
    setLibraryWalletConnect: (state, action) => {
      state.libraryProvider = action.payload;
    },
  },
});
export const {
  setMetaMaskToken,
  setProvider,
  setChainId,
  setLibrary,
  setNetwork,
  setMobileUIWallet,
  saveWindow,
  setDisconnectWallet,
  setNetworkStatus, setLibraryWalletConnect, setCurrentProvider, setSliderBoolean } = metaMaskTokenSlice.actions;
export default metaMaskTokenSlice.reducer;
