/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, lazy, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import { ChainID } from "./utils/constant/constant";
import UseModalInjector from "./utils/helper/useWeb3Modal";
import { setupAxios } from "./utils/axiosClient";
import { CompLoader } from "./components/_common/loader/Loader";
import { getMaintenanceMode } from "./services/user.services";
import { saveMaintenanceCheck } from "./store/reducers/user";
const LandingPage = lazy(() => import("./components/LandingPage"));
const NativeFarm = lazy(() => import("./components/NativeFarm"));
const ConvertBYN = lazy(() => import("./components/convert"));
const ZKAutoFarm = lazy(() => import("./components/zk-auto-farm"));
const BridgeComponent = lazy(() => import("./components/bridge"));
const Swap = lazy(() => import("./components/Swap"));
const DashboardComponent = lazy(() => import("./components/Dashboard"));

const App = () => {


  const dispatch = useDispatch()
  const loaction = useLocation();
  const { isWrongNetwork, chainId, switchToNetwork, getAccountDetails } =
    UseModalInjector();
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);


  const handleWalletConnectSlider = async () => {
    try {
      await switchToNetwork();
      await getAccountDetails();
    } catch (error) { }
  };
  /*  Is describing the purpose of the `handleGetMaintenance` function. This function is responsible for making an API call to
  check if the website is currently under maintenance. It retrieves the maintenance status from the
  response and dispatches it to the Redux store using the `saveMaintenanceCheck` action. */
  const handleGetMaintenance = async () => {
    try {
      const response = await getMaintenanceMode()
      dispatch(saveMaintenanceCheck(response?.data?.maintenance?.maintenance))
    } catch (error) {

    }
  }

  useEffect(() => {
    handleGetMaintenance()
    if (
      Number(chainId) !== Number(ChainID) &&
      loaction?.pathname !== "/" &&
      metaMaskToken
    ) {
      handleWalletConnectSlider();
    }
  }, [metaMaskToken, isWrongNetwork, chainId, loaction?.pathname]);

  useEffect(() => {

    setupAxios();
  }, []);
  return (
    <HelmetProvider>
      <ToastContainer
        hideProgressBar={true}
        position="top-right"
        className="toaster-container"
        newestOnTop={false} // To make sure new toasts appear on the left
      />
      <Suspense fallback={<CompLoader />}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/native-farm" element={<NativeFarm />} />
          <Route path="/conversion" element={<ConvertBYN />} />
          <Route path="/zk-auto-farming" element={<ZKAutoFarm />} />
          <Route path="/bridge" element={<BridgeComponent />} />
          <Route path="/dashboard" element={<DashboardComponent />} />
          <Route path="/swap" element={<Swap />} />
        </Routes>
      </Suspense>
    </HelmetProvider>
  );
};

export default App;
