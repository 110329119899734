import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  poolData: null,
  dashType: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setPooldata: (state, action) => {
      state.poolData = action.payload;
    },
    setType: (state, action) => {
      state.dashType = action.payload;
    },
  },
});

export const { setPooldata, setType } = dashboardSlice.actions;

export default dashboardSlice.reducer;
